import React from "react"
import { StaticQuery, graphql } from "gatsby"
import NavHolder from "./nav"

const HEADER_QUERY = graphql`
  query SiteTitleQuery {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`

const Header = props => (
  <StaticQuery
    query={HEADER_QUERY}
    render={data => {
      return (
        <>
        <div class="investor-banner" >
          <ul className="container | px-0">
            <li>
              <a href="https://investors.appfolioim.com/idealcapgroup/investor/contact-us" target="_blank"  rel="noopener noreferrer"> Become an Investor</a>
            </li>
             <li>|</li> 
            <li>
              <a href="https://investors.appfolioim.com/idealcapgroup/investor" target="_blank"  rel="noopener noreferrer">Investor Login</a>
            </li>
          </ul>  
        </div>
        <div id='masthead' className='site-header fabian'>
          <NavHolder />

          <div className='site-branding-container'>
            <div className='site-branding'></div>
            {/* .site-branding */}
          </div>
          {/* .layout-wrap */}
        </div>
        </>
      )
    }}
  />
)

export default Header
