import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import SvgIdealCapitalGroupLogoFooter from './icons/logos/IdealCapitalGroupLogoFooter';
import FooterMenu from './footermenu';
import { Container, Row, Col } from 'reactstrap';

const FOOTER_QUERY = graphql`
    query SiteFooterQuery {
        site {
            siteMetadata {
                address
                city
                state
                zip
                phoneFormat
                phone
            }
        }
    }
`;
const Footer = (props) => (
    <StaticQuery
        query={FOOTER_QUERY}
        render={(data) => {
            return (
                <footer id="colophon" className="site-footer">
                    <Container className="site-footer__disclaimer">
                        <small>
                            Please remember that investing involves risk and past performance may
                            not be indicative of future results. Different types of investments
                            involve varying degrees of risk, and there can be no assurance that the
                            future performance of any specific investment, investment strategy, or
                            product made referenced to directly or indirectly throughout this
                            website or other materials provided by IDEAL Capital Group Holdings LLC
                            (“IDEAL”) will be profitable, equal any corresponding indicated
                            historical performance level(s), or be suitable for your portfolio.
                            <br />
                            <br />
                            The information contained herein has been compiled by IDEAL from sources
                            believed to be reliable, but no representation or warranty, express or
                            implied, is made by IDEAL, its affiliates, or any other person as to its
                            accuracy, completeness, or correctness. All estimates, opinions, and
                            other information contained herein are subject to change without notice
                            and are provided in good faith but without legal responsibility or
                            liability. The information herein is provided for information purposes
                            only and does not constitute an offer or solicitation to buy or sell
                            securities. Neither IDEAL Capital Group Holdings LLC nor any of its
                            affiliates provide tax advice and do not represent in any manner that
                            the outcomes described herein will result in any particular tax
                            consequence.
                        </small>
                    </Container>
                    <div className="footer-arrow-container"></div>
                    <Container>
                        <Row className="footer__content">
                            <Col sm={{ size: 4, offset: 1 }} md={{ size: 7 }} lg={{ size: 5 }}>
                                <FooterMenu
                                    outerclass={'navbar footer__nav'}
                                    innerclass={'footer__nav-item'}
                                    menulink={'footer__nav-link'}
                                />
                            </Col>
                            <Col
                                lg={{ size: 2, offset: 1 }}
                                className="d-none d-lg-block footer__logo"
                            >
                                <SvgIdealCapitalGroupLogoFooter />
                            </Col>
                            <Col
                                sm={{ size: 6, offset: 1 }}
                                md={{ size: 4 }}
                                className="footer__logo"
                            >
                                <SvgIdealCapitalGroupLogoFooter className="d-lg-none" />
                                <p className="footer__address">
                                    {data.site.siteMetadata.address} {data.site.siteMetadata.city},{' '}
                                    {data.site.siteMetadata.state} {data.site.siteMetadata.zip}
                                </p>
                                <a
                                    className="footer__phone"
                                    href={`tel:${data.site.siteMetadata.phone}`}
                                >
                                    {data.site.siteMetadata.phoneFormat}
                                </a>
                            </Col>
                        </Row>
                    </Container>

                    <a
                        className="footer__byline"
                        href="https://primary360.com"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Website by PRIMARY
                    </a>
                </footer>
            );
        }}
    />
);

export default Footer;
