import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { Nav, NavItem, NavLink } from 'reactstrap';
import SvgLinkedInLogoFooter from "./icons/logos/LinkedInLogoFooter";

const FOOTER_MENU_QUERY = graphql`
  query GET_FOOTERMENU_ITEMS {
    site {
        siteMetadata {
          wordPressUrl
        }
      }
    wpgraphql {
      menuItems(where: { location: MENU_1 }) {
        nodes {
          id
          label
          url
          connectedObject {
            __typename
          }
        }
      }
    }
  }
`

const createLocalLink = (url, wpUrl) => {
  if (`#` === url) {
    return null
  }
  url = "/" + url.replace(wpUrl, ``)
  return url.replace('//', '/')
}


const renderMenuItem = (menuItem, wpUrl, inner, mlink) => {
  const link = createLocalLink(menuItem.url, wpUrl)

    return (
      <NavItem className={inner}>
        <NavLink
        key={menuItem.id}
        href={link}
        className={`Nav-item ${mlink}`}>
        {menuItem.label} </NavLink>
      </NavItem>
    )
}

const FooterMenu = (props) => (
  <Nav className={props.outerclass}>
  <StaticQuery
    query={FOOTER_MENU_QUERY}
    render={({ wpgraphql, site }) => {
      const wpUrl = site.siteMetadata.wordPressUrl
      const inner = props.innerclass
      const mlink = props.menulink
      if (wpgraphql.menuItems) {
        return (
            <>
                {wpgraphql.menuItems.nodes.map(menuItem => {
                    return renderMenuItem(menuItem, wpUrl, inner, mlink)
                })}
            </>
        )
      }
      
    }}
  />
    <NavItem className={`footer__nav-item nav-item`}>
      <NavLink
      key={`000`}
      href={`https://www.linkedin.com/company/ideal-capital-group`}
      className={`Nav-item footer__nav-link nav-link linkedin`}
      target={`_blank`}>
        <SvgLinkedInLogoFooter /> 
      </NavLink>
    </NavItem>
  </Nav>
)

export default FooterMenu
