import React from "react";

const ILogo = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 360 116.98">
    <g>
      <path d="M192.75,448v0a6.41,6.41,0,0,1,6.54-6.55,6.62,6.62,0,0,1,5,2L202.81,445a5,5,0,0,0-3.54-1.58,4.26,4.26,0,0,0-4.18,4.47v0a4.27,4.27,0,0,0,4.18,4.49,5,5,0,0,0,3.64-1.63l1.43,1.45a6.57,6.57,0,0,1-5.15,2.24A6.37,6.37,0,0,1,192.75,448Z" transform="translate(-126 -337.51)" fill="#8dc8e8"/>
      <path d="M215.7,441.51h2.06l5.58,12.77H221l-1.28-3.06h-6l-1.31,3.06h-2.28Zm3.19,7.73-2.2-5.07-2.17,5.07Z" transform="translate(-126 -337.51)" fill="#8dc8e8"/>
      <path d="M230.05,441.6h5c3,0,4.81,1.69,4.81,4.24v0c0,2.84-2.28,4.32-5.07,4.32h-2.51v4.08h-2.23Zm4.81,6.59c1.69,0,2.74-.94,2.74-2.26v0c0-1.48-1.07-2.26-2.74-2.26h-2.58v4.56Z" transform="translate(-126 -337.51)" fill="#8dc8e8"/>
      <path d="M246.81,441.6H249v12.68h-2.22Z" transform="translate(-126 -337.51)" fill="#8dc8e8"/>
      <path d="M260.06,443.67h-4V441.6h10.28v2.07h-4v10.61h-2.25Z" transform="translate(-126 -337.51)" fill="#8dc8e8"/>
      <path d="M276.08,441.51h2.06l5.58,12.77h-2.36l-1.28-3.06h-6l-1.31,3.06H270.5Zm3.18,7.73-2.19-5.07-2.17,5.07Z" transform="translate(-126 -337.51)" fill="#8dc8e8"/>
      <path d="M290.43,441.6h2.22v10.65h6.67v2h-8.89Z" transform="translate(-126 -337.51)" fill="#8dc8e8"/>
      <path d="M315.57,448v0a6.46,6.46,0,0,1,6.54-6.55,6.83,6.83,0,0,1,4.87,1.72l-1.42,1.68a5,5,0,0,0-3.54-1.36,4.29,4.29,0,0,0-4.11,4.47v0a4.23,4.23,0,0,0,4.3,4.53,5.14,5.14,0,0,0,3.06-1v-2.38H322V447.2h5.4v5.32a8.07,8.07,0,0,1-5.29,2A6.29,6.29,0,0,1,315.57,448Z" transform="translate(-126 -337.51)" fill="#8dc8e8"/>
      <path d="M334.87,441.6h5.64a5.12,5.12,0,0,1,3.66,1.27,3.83,3.83,0,0,1,1.05,2.73v0a3.81,3.81,0,0,1-3,3.84l3.43,4.8H343l-3.11-4.42h-2.79v4.42h-2.22Zm5.48,6.29c1.59,0,2.61-.84,2.61-2.12v0c0-1.36-1-2.1-2.63-2.1h-3.24v4.26Z" transform="translate(-126 -337.51)" fill="#8dc8e8"/>
      <path d="M351.93,448v0a6.63,6.63,0,0,1,13.25,0v0a6.63,6.63,0,0,1-13.25,0Zm10.92,0v0a4.35,4.35,0,0,0-4.31-4.51,4.28,4.28,0,0,0-4.27,4.47v0a4.33,4.33,0,0,0,4.31,4.49A4.27,4.27,0,0,0,362.85,448Z" transform="translate(-126 -337.51)" fill="#8dc8e8"/>
      <path d="M372.22,448.9v-7.3h2.23v7.21c0,2.35,1.21,3.6,3.2,3.6s3.19-1.18,3.19-3.51v-7.3h2.22v7.19c0,3.78-2.13,5.68-5.45,5.68S372.22,452.57,372.22,448.9Z" transform="translate(-126 -337.51)" fill="#8dc8e8"/>
      <path d="M390.66,441.6h5c3,0,4.81,1.69,4.81,4.24v0c0,2.84-2.28,4.32-5.07,4.32h-2.51v4.08h-2.23Zm4.82,6.59c1.68,0,2.73-.94,2.73-2.26v0c0-1.48-1.07-2.26-2.73-2.26h-2.59v4.56Z" transform="translate(-126 -337.51)" fill="#8dc8e8"/>
    </g>
    <polygon points="149.91 55.12 149.91 73.06 187.89 71.85 187.89 59.86 149.91 55.12" fill="#8dc8e8"/>
    <polygon points="139.28 61.82 149.91 55.12 149.91 73.06 139.28 70.51 139.28 61.82" fill="#73afca"/>
    <polygon points="139.28 44.65 149.91 27.92 149.91 45.15 139.28 53.34 139.28 44.65" fill="#00608b"/>
    <polygon points="171.62 33.98 149.91 27.92 149.91 45.15 171.62 49.17 171.62 33.98" fill="#0076a8"/>
    <g>
      <polygon points="187.89 17.95 149.91 0 149.91 17.23 187.89 30.56 187.89 17.95" fill="#00426a"/>
      <polygon points="139.28 26.45 149.91 0 149.91 17.23 139.28 35.13 139.28 26.45" fill="#00304d"/>
      <path d="M126,345.11h11.47v65.28H126Z" transform="translate(-126 -337.51)" fill="#00426a"/>
      <polygon points="360 62.44 326.36 62.44 326.36 7.6 314.89 7.6 314.89 72.89 355.74 72.89 360 62.44" fill="#00426a"/>
      <path d="M202.58,345.11H178.24v65.28h24.34c20.52,0,34.69-14.27,34.69-32.64v-.18C237.27,359.19,223.1,345.11,202.58,345.11Zm22.66,32.83c0,12.78-9,22-22.66,22H186.06l3.65-2.37v-42h12.87c13.71,0,22.66,9.42,22.66,22.19Z" transform="translate(-126 -337.51)" fill="#00426a"/>
      <path d="M389.44,390.27l8.34,20.12h12.13l-28.73-65.75H370.55l-28.72,65.75h11.75l6.71-15.76h27.38Zm-27.78-5.8,3.92-2.54,10.1-23.58L387,384.47Z" transform="translate(-126 -337.51)" fill="#00426a"/>
    </g>
  </svg>

);

export default ILogo;

